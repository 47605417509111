import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Content from "../common/Content";
import { scrollToTop } from "../common/GeneralMethod";
import { useLoading } from "../hooks/useLoading";
import Footer from "../common/Footer";
import Header from "../common/Header";
import LogoContent from "../common/LogoContent";

const RefundPolicy = () => {
  
 
  return (
    <div>
      {/* Header */}
      <header className="navbar-light header-sticky">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <LogoContent></LogoContent>

            {/*  */}
          
            
          </div>
        </nav>

        {/*  */}
       
      </header>
      <main>
        <div className="main-content my-5 py-3">
          <div className="refundPolicy">
            <div className="container">
              <h6 className="mb-3">
                {"Refund Policy updated Date: 26 Dec 2024"}
              </h6>
              <p>
                At CabKro.com, we aim to provide exceptional service and ensure
                customer satisfaction. This Refund Policy outlines the terms
                and conditions for refunds related to bookings made through our
                cab booking application.
              </p>

              <h6>1. Cancellation by the Customer</h6>
              <ul>
                <li>
                  Refunds for cancellations will depend on the timing of the
                  cancellation:
                  <ul>
                    <li>
                      <strong>More than 24 hours before the scheduled ride:</strong> 
                      Full refund.
                    </li>
                    <li>
                      <strong>Less than 24 hours before the scheduled ride:</strong> 
                      Partial refund, excluding cancellation fees.
                    </li>
                    <li>
                      <strong>After the scheduled ride time:</strong> No refund.
                    </li>
                  </ul>
                </li>
              </ul>

              <h6>2. Cancellation by CabKro</h6>
              <p>
                If CabKro cancels your booking due to unforeseen circumstances:
              </p>
              <ul>
                <li>
                  You will receive a full refund, including any service charges.
                </li>
                <li>
                  Alternatively, you may reschedule your ride at no additional
                  cost.
                </li>
              </ul>

              <h6>3. Refund Processing</h6>
              <ul>
                <li>
                  Refunds will be processed within 5-7 business days from the
                  date of cancellation.
                </li>
                <li>
                  Refunds will be issued to the original payment method used at
                  the time of booking.
                </li>
              </ul>

              <h6>4. Non-Refundable Scenarios</h6>
              <ul>
                <li>No-show by the customer.</li>
                <li>
                  Delays caused by incorrect or incomplete information provided
                  by the customer.
                </li>
                <li>
                  Cancellations due to violations of our Terms and Conditions.
                </li>
              </ul>

              <h6>5. Contact Us</h6>
              <p>
                If you have any questions or concerns about this Refund Policy,
                please contact us:
              </p>
              <ul>
                <li><strong>Email:</strong> contactus@cabkro.com</li>
                <li><strong>Phone:</strong> +91 9997325252</li>
                <li><strong>Address:</strong> MATHURA, UTTAR PRADESH</li>
              </ul>

              <p>Thank you for choosing CabKro!</p>
            </div>
          </div>
        </div>
      </main>

      {/* <Footer></Footer> */}
      {/* <BackToTop></BackToTop> */}
    </div>
  );
};

export default RefundPolicy;
