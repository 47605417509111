import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Content from "../common/Content";
import { scrollToTop } from "../common/GeneralMethod";
import { useLoading } from "../hooks/useLoading";
import Footer from "../common/Footer";
import Header from "../common/Header";
import LogoContent from "../common/LogoContent";

const PrivacyPolicy = ({apiName,heading}) => {
  const location = useLocation();
  const { startLoading, stopLoading} = useLoading()
  const [policyData, setPolicyData] = useState([]);

  useEffect(() => {
      scrollToTop();

    const fetchData = async () => {
      startLoading()
      await axios({
        method: "get",
        url: process.env.REACT_APP_API_URL + apiName,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response)
          setPolicyData([...response.data.data]);
          stopLoading()

          //console.log(response.data.data);
        })
        .catch(function (err) {
          // console.log(err);
          stopLoading()
        });
    };
    fetchData();
  }, [apiName]);

  return (
    <div>
      {/* Header */}
      <header className="navbar-light header-sticky">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <LogoContent></LogoContent>

            {/*  */}
          
            
          </div>
        </nav>

        {/*  */}
       
      </header>
      <main>
        <div className="main-content my-5 py-3">
          <div className="privacyPolicy">
            <div className="container">
              <h6 className="mb-3">{"Privacy Policy Effective Date: 26 Dec 2024"}</h6>
              <p>Welcome to CabKro.com! This Privacy Policy outlines how CabKro (“we,” “our,” or “us”) collects, uses, and protects your personal information when you use our cab booking application and related services (the “Service”). Your privacy is important to us, and we are committed to protecting your personal information.</p>

<h6>1. Information We Collect</h6>
<p><strong>1.1 Personal Information</strong></p>
<ul>
    <li>Name</li>
    <li>Email address</li>
    <li>Phone number</li>
    <li>Address</li>
</ul>

<p><strong>1.2 Non-Personal Information</strong></p>
<ul>
    <li>Device information (e.g., device model, operating system, browser type)</li>
    <li>Log data (e.g., IP address, usage data, timestamps)</li>
    <li>Location data (to provide cab services)</li>
</ul>

<p><strong>1.3 Information from Third Parties</strong></p>
<ul>
    <li>Social media profiles (if you sign up via third-party platforms like Google or Facebook).</li>
</ul>

<h6>2. How We Use Your Information</h6>
<p>We use your information for the following purposes:</p>
<ul>
    <li>To provide and manage our services.</li>
    <li>To process payments and bookings.</li>
    <li>To improve user experience and customize the Service.</li>
    <li>To communicate with you, including sending updates, promotions, and support messages.</li>
    <li>To ensure security and prevent fraud.</li>
    <li>To comply with legal obligations.</li>
</ul>

<h6>3. Sharing Your Information</h6>
<p>We do not sell your personal information. However, we may share your information with:</p>
<ul>
    <li><strong>Service Providers:</strong> Third-party vendors that assist with payment processing, map services, and customer support.</li>
    <li><strong>Business Partners:</strong> Trusted partners for marketing and promotional activities.</li>
    <li><strong>Legal Authorities:</strong> When required by law or to protect our legal rights.</li>
</ul>

<h6>4. Data Retention</h6>
<p>We retain your information as long as necessary to provide the Service and for other legitimate purposes such as complying with legal requirements, resolving disputes, and enforcing agreements.</p>

<h6>5. Security</h6>
<p>We implement appropriate technical and organizational measures to protect your data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

<h6>6. Your Rights</h6>
<p>Depending on your location, you may have the following rights:</p>
<ul>
    <li>Access, update, or delete your personal information.</li>
    <li>Opt-out of receiving marketing communications.</li>
    <li>Restrict or object to certain data processing.</li>
    <li>File a complaint with a data protection authority.</li>
</ul>
<p>To exercise these rights, contact us at contactus@cabkro.com.</p>

<h6>7. Cookies and Tracking Technologies</h6>
<p>We use cookies and similar tracking technologies to enhance your experience. You can manage your cookie preferences through your browser settings.</p>

<h6>8. Third-Party Links</h6>
<p>Our Service may contain links to third-party websites. We are not responsible for their privacy practices. We encourage you to read their privacy policies.</p>

<h6>9. Changes to This Policy</h6>
<p>We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated effective date. We recommend reviewing this policy periodically.</p>

<h6>10. Contact Us</h6>
<p>If you have questions or concerns about this Privacy Policy, please contact us:</p>
<ul>
    <li><strong>Email:</strong> contactus@cabkro.com</li>
    <li><strong>Phone:</strong> +91 9997325252</li>
    <li><strong>Address:</strong> MATHURA, UTTAR PRADESH</li>
</ul>

<p>Thank you for trusting CabKro with your information!</p>
</div>

            </div>
          </div>
      </main>

      {/* <Footer></Footer> */}
      {/* <BackToTop></BackToTop> */}
    </div>
  );
};

export default PrivacyPolicy;
