import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Content from "../common/Content";
import { scrollToTop } from "../common/GeneralMethod";
import { useLoading } from "../hooks/useLoading";
import Footer from "../common/Footer";
import Header from "../common/Header";
import LogoContent from "../common/LogoContent";

const TermsAndConditions = () => {
  
 
  return (
    <div>
      {/* Header */}
      <header className="navbar-light header-sticky">
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            <LogoContent></LogoContent>

            {/*  */}
          
            
          </div>
        </nav>

        {/*  */}
       
      </header>
      <main>
        <div className="main-content my-5 py-3">
          <div className="termsAndConditions">
            <div className="container">
              <h6 className="mb-3">
                {"Terms and Conditions Updated Date: 26 Dec 2024"}
              </h6>
              <p>Welcome to CabKro.com! These Terms and Conditions govern your use of our cab booking application and related services (the “Service”). By accessing or using the Service, you agree to these terms. If you do not agree, please do not use the Service.</p>

              <h6>1. Acceptance of Terms</h6>
              <p>By accessing or using our Service, you agree to comply with and be bound by these Terms and Conditions, as well as our Privacy Policy.</p>

              <h6>2. User Obligations</h6>
              <p>You agree to:</p>
              <ul>
                <li>Provide accurate and up-to-date information.</li>
                <li>Use the Service lawfully and responsibly.</li>
                <li>Refrain from using the Service for fraudulent or unauthorized purposes.</li>
              </ul>

              <h6>3. Booking and Payment</h6>
              <ul>
                <li>Bookings are subject to availability.</li>
                <li>Payments must be made as specified during the booking process.</li>
                <li>All payment information must be accurate and authorized.</li>
              </ul>

              <h6>4. Cancellations and Refunds</h6>
              <p>Cancellation and refund policies are outlined in our Refund Policy. Please review these terms before making a booking.</p>

              <h6>5. Limitation of Liability</h6>
              <p>We are not liable for:</p>
              <ul>
                <li>Any indirect, incidental, or consequential damages arising from the use of the Service.</li>
                <li>Delays or disruptions caused by third parties.</li>
              </ul>

              <h6>6. Intellectual Property</h6>
              <p>All content, trademarks, and intellectual property on the Service are the property of CabKro or its licensors. Unauthorized use is prohibited.</p>

              <h6>7. Termination</h6>
              <p>We reserve the right to suspend or terminate your access to the Service if you breach these Terms and Conditions.</p>

              <h6>8. Governing Law</h6>
              <p>These terms are governed by the laws of India. Any disputes will be resolved under the jurisdiction of Mathura, Uttar Pradesh courts.</p>

              <h6>9. Amendments</h6>
              <p>We may update these Terms and Conditions from time to time. Changes will be posted on this page with an updated effective date.</p>

              <h6>10. Contact Us</h6>
              <p>If you have questions or concerns about these Terms and Conditions, please contact us:</p>
              <ul>
                <li><strong>Email:</strong> contactus@cabkro.com</li>
                <li><strong>Phone:</strong> +91 9997325252</li>
                <li><strong>Address:</strong> MATHURA, UTTAR PRADESH</li>
              </ul>

              <p>Thank you for using CabKro!</p>
            </div>
          </div>
        </div>
      </main>

      {/* <Footer></Footer> */}
      {/* <BackToTop></BackToTop> */}
    </div>
  );
};

export default TermsAndConditions;
