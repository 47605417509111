import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Home/Home";

import "react-notifications/lib/notifications.css";
import { AuthProvider } from "./hooks/useAuth";
import { LoginModalProvider } from "./hooks/useLoginModal";

import BookingNew from "./BookingPage/BookingNew";
import ProtectedRoutes from "./ProtectedRoutes";
import { BookNowModalProvider } from "./hooks/useBookNowModal";
import { BookingDataProvider } from "./hooks/useBookingData";
import { BookingSelectedDetaisProvider } from "./hooks/useBookingSelectedDetails";
import { InternetConnectionProvider } from "./hooks/useInternetConnection";
import { LoadingProvider } from "./hooks/useLoading";
import { LoginStepProvider } from "./hooks/useLoginStep";
import { ManageBookingProvider } from "./hooks/useManageBookingData";
import { PageProvider } from "./hooks/usePage";
import { ProfileActiveProvider } from "./hooks/useProfileSection";
import PrivacyPolicy from "./Routes/PrivacyPolicy";
import RefundPolicy from "./Routes/RefundPolicy";
import TermsAndConditions from "./Routes/TermsAndConditions";
function App() {
  return (
    <>
      <AuthProvider>
        <InternetConnectionProvider>
          <PageProvider>
            <LoginModalProvider>
              <BookNowModalProvider>
                <LoadingProvider>
                  <LoginStepProvider>
                    <BookingSelectedDetaisProvider>
                      <BookingDataProvider>
                        <ManageBookingProvider>
                          <ProfileActiveProvider>
                            <Routes>
                              <Route path="/" element={<Home></Home>} />

                              <Route path="/privacy-policy" element={<PrivacyPolicy></PrivacyPolicy>} />
                              <Route path="/refund-policy" element={<RefundPolicy></RefundPolicy>} />

                              <Route path="/terms-and-conditions" element={<TermsAndConditions></TermsAndConditions>} />

                              {/* <Route
                              path="/forgot-password"
                              element={<ForgotPassword></ForgotPassword>}
                            />
                            <Route path="/policy" element={<Policy />} /> */}
                              <Route element={<ProtectedRoutes />}>
                                <Route
                                  path="/booking"
                                  element={<BookingNew></BookingNew>}
                                />
                              </Route>
                              <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                          </ProfileActiveProvider>
                        </ManageBookingProvider>
                      </BookingDataProvider>
                    </BookingSelectedDetaisProvider>
                  </LoginStepProvider>
                </LoadingProvider>
              </BookNowModalProvider>
            </LoginModalProvider>
          </PageProvider>
        </InternetConnectionProvider>
      </AuthProvider>
    </>
  );
}

export default App;
